import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CustomMenuProp } from "../../Utils/helpers";
import { api } from "../../customAxios";
function AddTaskModalWindow(props) {
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    refreshPage,
    handleUpdateTask,
    isViewTask,
    id,
    isTaskList,
    projectSprints,
    projectId,
    taskId,
    taskListId,
    sprintId,
    isList,
    setIsList,
    isListToList,
    isGlobal,
    gobalTask,
    loading,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    sprint_id: Yup.string().required(
      isList ? "Please Select List" : "Please Select Sprint"
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = new FormData();
    if (isList) {
      if (isGlobal && gobalTask?.sprintName?.sprint_name) {
        payload.append("sprint_id", gobalTask?.sprintName?.sprint_id);
      } else if (!isListToList) {
        payload.append("sprint_id", sprintId);
      }
      const taskIds = [taskId];
      taskIds.forEach((taskId, index) => {
        payload.append(`task_id[${index}]`, taskId);
      });
      payload.append("task_list_id", data.sprint_id);
      const url = isListToList
        ? "/move-task-to-other-list"
        : "/task-move-sprint-to-tasklist";
      const urlGobel =
        isGlobal && gobalTask?.sprintName?.sprint_name
          ? "/task-move-sprint-to-tasklist"
          : "/move-task-to-other-list";
      api
        .post(isGlobal ? urlGobel : url, payload)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Task moved to list successfully");
            refreshPage(
              taskId,
              projectId,
              null,
              null,
              isListToList ? taskListId : sprintId,
              data.sprint_id,
              null,
              isGlobal ? "Gobal" : "ListToList"
            );
            setOpen(false);
            setIsLoading(false);
            setValue("sprint_id", "");
          }
        })
        .catch((error) => {
          toast.error("Oops! something went wrong.");
          setIsLoading(false);
        });
    } else {
      payload.append("sprint_id", isTaskList ? data.sprint_id : id);
      const taskIds = [taskId];
      taskIds.forEach((taskId, index) => {
        payload.append(`task_id[${index}]`, taskId);
      });
      api
        .post(`/sprint-tasks`, payload)
        .then((res) => {
          if (res.status === 200) {
            const SelectedSprint = projectSprints.find(
              (sprint) => sprint.id == (isTaskList ? data.sprint_id : id)
            );
            toast.success("Task moved to sprint successfully");
            if (isViewTask) {
              handleUpdateTask(null, null, SelectedSprint);
            }
            refreshPage(
              taskId,
              projectId,
              null,
              null,
              isTaskList ? data.sprint_id : isGlobal ? data.sprint_id : id,
              taskListId,
              null,
              isGlobal ? "Global" : "move to sprint",
              SelectedSprint
            );
            setOpen(false);
            setIsLoading(false);
            setValue("sprint_id", "");
          } else {
            throw new Error("Non-200 status code received");
          }
        })
        .catch((err) => {
          toast.error("Oops! something went wrong.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setIsLoading(false);
    setValue("sprint_id", "");
  };

  useEffect(() => {
    if (!open && setIsList) {
      setIsList(false);
    }
  }, [open]);
  return (
    <div>
      <Modal
        size="lg"
        show={open}
        // onHide={handleCancel}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pt-5 pl-5 add-member_modal"
      >
        <div className="container">
          <div className="main-modal">
            <Modal.Header className="project-modal_header">
              <Modal.Title>
                {isList ? "Move To Task List" : "Move To Sprint"}
              </Modal.Title>
              <FontAwesomeIcon icon={faClose} onClick={() => handleCancel()} />
            </Modal.Header>
            <Modal.Body className="project-modal_body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="fields">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="project-name">
                        <h5 className="text-dark">
                          {isList ? "Task List" : "Sprint"}
                        </h5>
                        <Controller
                          name="sprint_id"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <div style={{ position: "relative" }}>
                              {!isValid ? (
                                <InputLabel
                                  htmlFor="demo-multiple-checkbox"
                                  className="move-task_sprint-label"
                                >
                                  {isList
                                    ? "Select a task list"
                                    : "Select a sprint"}
                                </InputLabel>
                              ) : null}
                              <Select
                                className={`mt-2 inputcontrasts w-100 copylist-selector mui-selector-color ${
                                  errors.sprint_id ? "is-invalid_boder" : ""
                                }`}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                name="sprint_id"
                                {...register("sprint_id")}
                                MenuProps={CustomMenuProp()}
                              >
                                {loading ? (
                                  <ClipLoader
                                    loading={loading}
                                    color="rgb(25 118 210)"
                                    size={30}
                                    className="move-loader"
                                  />
                                ) : projectSprints &&
                                  projectSprints.length > 0 ? (
                                  projectSprints
                                    .filter(
                                      (sprint) => sprint.status !== "completed"
                                    )
                                    .slice()
                                    .sort((a, b) => b.active - a.active)
                                    .map((sprint) => (
                                      <MenuItem
                                        key={sprint.id}
                                        value={sprint.id}
                                      >
                                        {`${sprint.name} ${
                                          sprint.active === 1 ? " - Active" : ""
                                        }`}
                                      </MenuItem>
                                    ))
                                ) : (
                                  <MenuItem value="">
                                    {isList
                                      ? "We have no list in this project"
                                      : "We have no sprint in this project"}
                                  </MenuItem>
                                )}
                              </Select>
                            </div>
                          )}
                        />
                        <span className="mt-2 mb-0 danger-color error-msg">
                          {errors.sprint_id && errors.sprint_id.message}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <button
                    type="button"
                    className="btn btn-cancel btn-sm"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`btn btn-primary btn-sm ${
                      isLoading ? "add-task-cliper-pd" : ""
                    }`}
                    disabled={isLoading || !isValid}
                  >
                    {isLoading ? (
                      <ClipLoader color="#fff" size={20} />
                    ) : (
                      "Add Task"
                    )}
                  </button>
                </div>
              </form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddTaskModalWindow;
