import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import Bug from "../../../../assets/images/Bug.png";
import TaskAssigned from "../../../../componets/comman/TaskAssigned";
import TaskStatus from "../../../../componets/comman/TaskStatus";
import TaskStoryPoint from "../../../../componets/comman/TaskStoryPoint";
import ConfirmDialog from "../../../../componets/shared/ConfirmDialog";
import { api } from "../../../../customAxios";
import TaskModal from "./TaskModal";

function AddSubBug(props) {
  const {
    taskDetail,
    handleGetTaskId,
    handleUpdateTask,
    projectDetails,
    projectUser,
    setTaskDetail,
    setProgress,
  } = props;
  const [isOpenTaskModelWindow, setIsOpenTaskModelWindow] = useState(false);
  const [subTasks, setSubTasks] = useState(
    taskDetail.subTasks
      ? taskDetail.subTasks.filter((task) => task.task_type === "B")
      : []
  );
  const [alert, setAlert] = useState(false);
  const [subBugId, setSubBugId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const handleOpenTaskWindow = () => {
    setIsOpenTaskModelWindow(true);
  };
  const refreshData = (newTask, subBugId) => {
    const updatedSubtasks = subTasks.filter(
      (subtask) => subtask.task_id !== subBugId
    );
    if (newTask) {
      updatedSubtasks.push(newTask);
    }
    setSubTasks(updatedSubtasks);
    setTaskDetail((prevValues) => {
      const updatedTask = {
        ...prevValues,
        subTasks: taskDetail?.subTasks.filter(
          (subtask) => subtask.task_id !== subBugId
        ),
      };
      refreshPage(null, updatedTask);
      return updatedTask;
    });
  };

  const handleCreateTask = async (data) => {
    await api
      .post("/tasks", data)
      .then((response) => {
        const newData = [...subTasks, response?.data?.task];
        setSubTasks(newData);
        setTaskDetail((prevValues) => {
          const updatedTask = {
            ...prevValues,
            subTasks: [...taskDetail?.subTasks, response?.data?.task],
          };
          refreshPage(null, updatedTask);
          return updatedTask;
        });
        setIsOpenTaskModelWindow(false);
        toast.success("Sub bug added successfully!");
      })
      .catch(function (error) {
        setIsOpenTaskModelWindow(false);
      });
  };
  const handleDeleteSubBug = (subBugId) => {
    setSubBugId(subBugId);
    setAlert(true);
  };
  const confirmDelete = () => {
    setProgress(30);
    setAlert(false);
    api
      .delete(`tasks/${subBugId}`)
      .then((action) => {
        setProgress(60);
        if (action.status === 200) {
          refreshData(null, subBugId);
          toast.success("Sub bug deleted successfully!");
        }
      })
      .catch((error) => {
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setProgress(100);
      });
  };
  const handleDrawerOpen = (id) => {
    const currentUrl = location.pathname;
    const newUrl = currentUrl.replace(/\/panel\/(\d+)/, `/panel/${id}`);

    navigate(newUrl);
  };
  const refreshPage = (prevStatusId, updatedData) => {
    const data = {
      taskId: updatedData.task_id,
      projectId: updatedData.project_id,
      updateTask: updatedData,
      oldStatusId: prevStatusId,
    };
    navigate(location.pathname, { state: { data } });
  };

  return (
    <>
      <div className="box mt-3 mb-4" id="bx">
        <div className="bx-r">
          <div className="box-head zack">
            <h5 className="text-secondary">Bugs</h5>
            <span
              className={`text-primary fs-12 ${taskDetail?.userPermission?.can_i_edit
                ? "cursor_pointer"
                : "cursor_not_allowed"
                }`}
              onClick={
                taskDetail?.userPermission?.can_i_edit
                  ? handleOpenTaskWindow
                  : null
              }
              style={{
                color: taskDetail?.userPermission?.can_i_edit ? "blue" : "gray",
              }}
            >
              + Add Bug
            </span>
          </div>
          {isOpenTaskModelWindow && (
            <TaskModal
              isOpenTaskModelWindow={isOpenTaskModelWindow}
              setIsOpenTaskModelWindow={setIsOpenTaskModelWindow}
              isSubTask={true}
              handleCreateTask={handleCreateTask}
              projectId={taskDetail.project_id}
              taskId={taskDetail.task_id}
              isTaskType={true}
              projectUser={projectUser}
              projectStatuses={projectDetails}
              sprintId={taskDetail?.sprintName?.sprint_id}
              setProgress={setProgress}
            />
          )}
          <div className="box-body">
            <div className="row m-0">
              {subTasks
                ? subTasks.map((task, i) => (
                  <div key={i} className="item view-subtask" id="items">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="item-content">
                          <div
                            className="col-md-8 col8-view-subtask"
                            onClick={() => handleDrawerOpen(task.task_id)}
                          >
                            <div className="text">
                              <div className="icon">
                                <img src={Bug} alt="" />
                              </div>
                              <p className="textEllipse task_title add_cursor">
                                {task?.task_name}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 d-flex align-items-center col4-view-subtask">
                            <TaskStatus
                              setProgress={setProgress}
                              statusList={projectDetails}
                              taskDetail={task}
                              isOverview={true}
                              isSubtaskView={true}
                              isList={true}
                              listTaskStatus={task?.status_id}
                              refreshPage={refreshPage}
                              projectId={taskDetail.project_id}
                            />
                            <TaskStoryPoint
                              refreshPage={refreshPage}
                              taskDetail={task}
                              isSubtaskview={true}
                              setProgress={setProgress}
                            />{" "}
                            <TaskAssigned
                              allUsers={projectUser}
                              assignees={task?.assignees}
                              isTaskList={true}
                              refreshPage={refreshPage}
                              isSubtaskview={true}
                              setProgress={setProgress}
                              taskId={task?.task_id}
                              taskDetail={taskDetail}
                            />
                            <div className="action subtask_dropdown">
                              <Dropdown>
                                <div
                                  className={`option-icon viewtask-dropdown ${(taskDetail.userPermission
                                    ?.can_i_delete === false ||
                                    task?.userPermission?.can_i_delete ===
                                    false) &&
                                    "bg-unset"
                                    }`}
                                >
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className={`button_style ${(taskDetail.userPermission
                                      ?.can_i_delete === false ||
                                      task?.userPermission?.can_i_delete ===
                                      false) &&
                                      "not-allowed-disable sprint-action"
                                      }`}
                                  >
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                  </Dropdown.Toggle>
                                  {(taskDetail.userPermission?.can_i_delete ||
                                    task?.userPermission?.can_i_delete) && (
                                      <Dropdown.Menu className="dropdown_menu_viewtask">
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleDeleteSubBug(task.task_id)
                                          }
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    )}
                                </div>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : ""}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={alert}
        setOpen={setAlert}
        dialogTitle="Are you sure ?"
        handleConfirmYes={confirmDelete}
      />
    </>
  );
}

export default AddSubBug;
